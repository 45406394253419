import {post} from './request';

// 获取场馆数据
export const loadChangGuanList = async () => {
    const res = await post('/changguan/list');
    return res?.data || [];
};

export const saveChangGuanInfo = async (data) => {
    const res = await post('/changguan/save', data);
    return res;
};

// 获取场馆信息
export const getChangGuanInfo = async (id) => {
    const res = await post('/changguan/get', {id});
    return res?.data;
};

export const deleteChangGuan = async (idList) => {
    const res = await post('/changguan/delete', {idList});
    return res;
};

// 加载场馆下场地数据
export const loadChangDiList = async (vid) => {
    const res = await post('/changdi/list', {vid});
    return res;
};

export const saveChangDiInfo = async (data) => {
    const res = await post('/changdi/save', data);
    return res;
};

export const deleteChangDiInfo = async (id) => {
    const res = await post('/changdi/delete', {id});
    return res;
};

export const getUserList = async (page, pageSize = 30) => {
    const res = await post('/user/list', {page, pageSize});
    return res;
};

// 修改用户角色和状态
export const modifyUserPermission = async (data) => {
    const res = await post('/user/modify/permission', data);
    return res;
};

// 重置用户密码
export const resetUserPassword = async (id) => {
    const res = await post('/user/reset/password', {id});
    return res;
};

// 修改密码
export const changePassword = async (oldPassword, newPassword) => {
    const res = await post('/user/modify/password', {oldPassword, newPassword});
    return res;
};

// 获取全部场地管理员
export const getChangDiAdmin = async () => {
    const res = await post('/user/changdi/admin');
    return res;
};

// 获取发球命令
export const getCommand = async () => {
    const res = await post('/command/get');
    return res;
};

// 获取发球命令
export const saveCommand = async (data) => {
    const res = await post('/command/save', data);
    return res;
};

// 获取配置文件
export const getConfig = async () => {
    try {
        const res = await post('/get/config');
        return res;
    } catch (e) {
    }
};


